import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={8}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2.828 6.364 4.95 4.95-1.414 1.414L0 6.364 6.364 0l1.414 1.414-4.95 4.95Z"
      fill="#000"
    />
  </svg>
)

export default SvgComponent
