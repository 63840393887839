import React, { useState, useContext, createContext } from 'react';

const HeaderBackroundContext = createContext();

const useHeaderBackground = () => {
  const [backgroundColor, setHeaderBackgroundColor] = useContext(HeaderBackroundContext);
 
  const handleColor = val => setHeaderBackgroundColor(val);
 
  return { value: backgroundColor, onChange: handleColor };
};


const HeaderBackroundProvider = ({ children }) => {
  const [backgroundColor, setHeaderBackgroundColor] = useState();
 
  return (
    <HeaderBackroundContext.Provider value={[backgroundColor, setHeaderBackgroundColor]}>
      {children}
    </HeaderBackroundContext.Provider>
  );
};

export { HeaderBackroundContext, useHeaderBackground, HeaderBackroundProvider };