import * as styles from "./Layout.module.scss"

import AnnouncementBanner from "../components/AnnouncementBanner"
import Footer from "./Footer"
import Header from "./Header"
import { HeaderBackroundProvider } from "../contexts/HeaderBackroundContext"
import Helmet from "react-helmet"
import PropTypes from "../util/PropTypes"
import React from "react"
import { Script } from "gatsby"
import logoOrange from "../images/logos/logo-orange.webp"

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

const Layout = ({ location, children }) => (
  <>
    <HeaderBackroundProvider>
      <Helmet>
        <meta
          name="google-site-verification"
          content="lSenvdT38EdQ1H08DF0Jx4SOKFKQvGxyP5M9Hy5EPwY"
        />
      </Helmet>

      {location.pathname?.includes("/footerIframe") ||
      location.pathname?.includes("/securing-ot-iomt-networks") ||
      location.pathname?.includes("/zero-trust-architecture-2023") ||
      location.pathname?.includes("/business-case-for-microsegmentation") ||
      location.pathname?.includes("/zero-trust-predictions-2023") ||
      location.pathname?.includes(
        "/stopping-ransomware-in-its-tracks-with-innovative-vlan-defense"
      ) ||
      location.pathname?.includes(
        "/zero-trust-microsegmentation-for-manufacturing"
      ) ||
      location.pathname?.includes(
        "/microsegmentation-and-endpoint-security"
      ) ? (
        <>
          <div className={styles.header}>
            <Header
              background="linear-gradient(180deg, #071b35 0%, #11395c 72.61%, #1a537e 136.29%)"
              pathname={location.pathname}
              colorOverride={"#f9f9f9"}
              imgOverride={logoOrange}
              bordercolor="2px solid #f9f9f9"
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.header}>
            <Header pathname={location.pathname} />
          </div>
        </>
      )}
      <main>{children}</main>
      {location.pathname?.includes("/footerIframe") ||
      location.pathname?.includes("/securing-ot-iomt-networks") ||
      location.pathname?.includes("/zero-trust-architecture-2023") ||
      location.pathname?.includes("/business-case-for-microsegmentation") ||
      location.pathname?.includes("/zero-trust-predictions-2023") ||
      location.pathname?.includes(
        "/zero-trust-microsegmentation-for-manufacturing"
      ) ||
      location.pathname?.includes(
        "/microsegmentation-and-endpoint-security"
      ) ? (
        <Footer />
      ) : (
        <Footer />
      )}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `
            (function () {
              var s = document.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://app.termly.io/embed.min.js';
              s.id = 'c180a60f-2fbf-4b7d-9f2a-6687a686631f';
              s.setAttribute("data-name", "termly-embed-banner");
              var x = document.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            })(); `,
        }}
      />
    </HeaderBackroundProvider>
  </>
)

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

export default Layout
