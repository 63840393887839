import "./mobile-nav-style.scss"

import * as styles from "./MobileNav.module.scss"

import { ArrowRight, Back, Close } from "../components/icons"
/* eslint-disable */
import React, { useState } from "react"

import { Link } from "gatsby"
import classNames from "classnames"
import logoDefault from "../images/logos/logo.webp"
import orangeArrowRight from "../images/icons/chevron-right.svg"
import platformlogo2 from "../images/infosec.png"

const MobileNav = ({ toggleNav, navigateTo, navData }) => {
  const [selectedItem, setSelectedItem] = useState(null)
  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.logo}>
            {selectedItem && (
              <div
                className={styles.back}
                onClick={() => {
                  setSelectedItem(null)
                }}
              >
                <Back />
              </div>
            )}

            <img className={styles.logo} src={logoDefault} alt="White logo" />
          </div>
          <div className={styles.rowEnd}>
            <span
              className={styles.text}
              onClick={() => {
                navigateTo("https://www.zscaler.com/company/contact")
                toggleNav()
              }}
            >
              {" "}
              Contact us
            </span>
            <Close
              className={styles.close}
              onClick={() => {
                setSelectedItem(null)
                toggleNav()
              }}
            />
          </div>
        </div>
        <div className={styles.content}>
          {navData.map((item, index) => (
            <div
              key={index}
              className={styles.menuItem}
              onClick={() => {
                setSelectedItem(item)
              }}
            >
              <div>{item.pageName}</div>
              <div>
                <ArrowRight />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={classNames(
          styles.selectOverlay,
          selectedItem && styles.selectOverlayActive
        )}
      >
        <div className={styles.container}>
          {selectedItem && (
            <div>
              <div className={styles.sublink}>
                {selectedItem.pageName && (
                  <div className={styles.sublinkHeader}>
                    {selectedItem.pageName === "Solutions"
                      ? "Zero Trust Enterprise"
                      : selectedItem.pageName}
                  </div>
                )}
                {selectedItem.dropdownData.map((item, index) => (
                  <div
                    key={index}
                    to={item.link}
                    className={`${styles.sublinkItem} ${
                      item.name === "Network Experience" ? styles.nw_ai : ""
                    }`}
                    onClick={() => {
                      navigateTo(item.link)
                      setSelectedItem(null)
                      toggleNav()
                    }}
                  >
                    {item.name}
                  </div>
                ))}
              </div>

              {(selectedItem.label === "Platform" ||
                selectedItem.label === "Resources" ||
                selectedItem.label === "Customers") && (
                <div className="img-section-platform">
                  <img
                    className="logo-platform"
                    src={platformlogo2}
                    alt="logo"
                  />
                  {selectedItem.label === "Customers" ? (
                    <p>Hot Company in Remote Workforce Security</p>
                  ) : (
                    <p>Best Product in Micro-Segmentation 2022</p>
                  )}
                  <Link
                    to={
                      selectedItem.label === "Customers"
                        ? "airgap-named-as-the-hot-company-remote-workforce-security"
                        : "/airgap-honored-as-the-best-product-in-micro-segmentation"
                    }
                  >
                    Press Release
                    <img
                      className="icon-platform"
                      src={orangeArrowRight}
                      alt="arrow"
                    />
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileNav
