// extracted by mini-css-extract-plugin
export var background_transparent = "Header-module--background_transparent--f6995";
export var burgerContainer = "Header-module--burgerContainer--deb47";
export var close = "Header-module--close--89cde";
export var header = "Header-module--header--2f53b";
export var headerLogo = "Header-module--header-logo--6ce4d";
export var headerRow = "Header-module--header-row--ce22a";
export var headerScrolled = "Header-module--header-scrolled--dcd6c";
export var logo = "Header-module--logo--3b2de";
export var logoActive = "Header-module--logo-active--96812";
export var overlay = "Header-module--overlay--a1bbc";
export var overlayContent = "Header-module--overlay-content--ac9f9";
export var overlayNav = "Header-module--overlay-nav--9d235";
export var overlayOpen = "Header-module--overlay-open--eb396";
export var overlaySubmenu = "Header-module--overlay-submenu--dac9f";
export var overlaySubnav = "Header-module--overlay-subnav--ee800";
export var overlaySubnavText = "Header-module--overlay-subnav-text--2cccf";
export var overlaySubnavTitle = "Header-module--overlay-subnav-title--9778b";
export var overlayWrapper = "Header-module--overlay-wrapper--de30c";
export var overlayWrapperOpen = "Header-module--overlay-wrapper-open--80e75";
export var text = "Header-module--text--db142";
export var toggle = "Header-module--toggle--fbd47";