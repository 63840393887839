import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.364 4.95 11.314 0l1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95L0 11.314l4.95-4.95L0 1.414 1.414 0l4.95 4.95Z"
      fill="#062D48"
    />
  </svg>
)

export default SvgComponent
