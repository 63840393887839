import React, { useState } from 'react';

import ZScalerLogo from "../images/Zscaler-logo.svg"
import closeImage from "../images/close-white.svg"

import "./AnnouncementBanner.scss"

const AnnouncementBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const handleClose = () => {
    setIsBannerVisible(false);
  };

  return (
    <>
      {isBannerVisible && (
        <div className='global-banner'>
          <p className='global-banner__message'>
                <span>Big News!  Airgap Networks is now a <img src={ZScalerLogo} alt="ZScaler logo" />company!</span>
                <a href="https://www.zscaler.com/press/zscaler-extends-zero-trust-sase-leadership-and-eliminates-need-firewall-based-segmentation">
                Read the news.
                </a>
        </p>
          <button onClick={handleClose} className='global-banner__close'>
            <img src={closeImage} alt="Close banner" />
          </button>
        </div>
      )}
    </>
  );
};

export default AnnouncementBanner;
