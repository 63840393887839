import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={8}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.95 6.364 0 1.414 1.414 0l6.364 6.364-6.364 6.364L0 11.314l4.95-4.95Z"
      fill="#EE8241"
    />
  </svg>
)

export default SvgComponent
