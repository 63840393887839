import "./styles/footer.scss"

import { Container } from "react-bootstrap"
import Link from "../components/Link"
import React from "react"
import facebookIcon from "../images/icons/facebook.svg"
import linkedinIcon from "../images/icons/linkedin.svg"
import logoDefault from "../images/Airgap_blue.svg"
import soclogo from "../images/logos/footer-soc.webp"
import twitterIcon from "../images/icons/twitter.svg"
import youtubeIcon from "../images/icons/youtube.svg"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid="xl" className="footer-container">
          <div className="footer_top">
            <div className="footer_top_right_wrapper">
              <div className="footer_logos">
                <Link className="" to="/">
                  <img className="footer_logo" src={logoDefault} alt="Logo" />
                </Link>
                <img
                  className="soc_logo"
                  src={soclogo}
                  alt="footer right logo"
                />
                <a href="https://www.uschamber.com">
                  <img
                    alt="U.S. Chamber of Commerce Member"
                    src="https://www.uschamber.com/assets/images/USCC_Webstickers_2022_120x60.png"
                    title="U.S. Chamber of Commerce Member"
                  />
                </a>
              </div>
              <div className="row pages-names">
                <div className=" col-sm-4 ">
                  <div className="footer_link_title">Explore</div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="/disposable-jump-box/"
                      className="footer_link_item"
                    >
                      Secure Access
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="/ransomware-kill-switch/"
                      className="footer_link_item"
                    >
                      Segmentation
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="/discovery-and-visibility/"
                      className="footer_link_item"
                    >
                      Discovery & Visibility
                    </Link>
                  </div>
                </div>

                <div className="col-sm-4 ">
                  <div className="footer_link_title">Learn</div>
                  <div className="footer_link_item_wrapper">
                    <Link to="/blog" className="footer_link_item">
                      Blog
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link to="/resources" className="footer_link_item">
                      Resources
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link to="/eula" className="footer_link_item">
                      EULA
                    </Link>
                  </div>
                </div>

                <div className="col-sm-4 ">
                  <div className="footer_link_title">Company</div>
                  <div className="footer_link_item_wrapper">
                    <Link to="/about" className="footer_link_item">
                      About Airgap
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="https://www.zscaler.com/partners"
                      className="footer_link_item"
                    >
                      Become a Partner
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="https://www.zscaler.com/partners"
                      className="footer_link_item"
                    >
                      Deal Registration
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link to="/careers" className="footer_link_item">
                      Careers
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="https://www.zscaler.com/company/news-press"
                      className="footer_link_item"
                    >
                      Press Releases
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link to="/privacy-policy" className="footer_link_item">
                      Privacy
                    </Link>
                  </div>
                  <div className="footer_link_item_wrapper">
                    <Link
                      to="/resources/#webinars"
                      className="footer_link_item"
                    >
                      Webinars
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_contactus_box">
              <div className="footer_contactus">
                <div className="contactus_title">Let’s get started</div>
                <div className="contactus_content">
                  Schedule your technical call and see why major companies are
                  switching to Airgap.
                </div>
                <a
                  href="https://www.zscaler.com/products-and-solutions/zero-trust-device-segmentation#request-a-demo"
                  className="contactus_button"
                >
                  Request a Demo
                </a>

                <div className="social_buttons_wrapper">
                  <Link
                    className="social_item"
                    to="https://www.facebook.com/airgap/"
                  >
                    <img src={facebookIcon} alt="Facebook icon" height="28" />
                  </Link>
                  <Link
                    className="social_item"
                    to="https://twitter.com/AirgapNetworks"
                  >
                    <img src={twitterIcon} alt="Twitter icon" height="28" />
                  </Link>
                  <Link
                    className="social_item"
                    to="https://www.linkedin.com/company/airgapnetworks"
                  >
                    <img src={linkedinIcon} alt="LinkedIn icon" height="28" />
                  </Link>
                  <Link
                    className="social_item"
                    to="https://www.youtube.com/airgapnetworks"
                  >
                    <img src={youtubeIcon} alt="Youtube icon" height="28" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="footer_bottom">
          <div className="footer_copyright">
            ©AIRGAP <span>2024</span>
          </div>
          <div className="footer_bottom_terms">
            <Link to="/terms-and-conditions" className="footer_bottom_link">
              Terms of Services
            </Link>
            <Link to="/supportterms/" className="footer_bottom_link">
              Terms of Support
            </Link>
            <Link to="/privacy-policy" className="footer_bottom_link">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
