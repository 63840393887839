exports.components = {
  "component---2119009370-ess-critical-infrastructure-with-ai-ml-technologies-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-networks-enhances-award-winning-zero-trust-firewall-with-threatgpt-to-protect-business-critical-infrastructure-with-ai-ml-technologies/index.md" /* webpackChunkName: "component---2119009370-ess-critical-infrastructure-with-ai-ml-technologies-index-md" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agentless-microsegmentation-jsx": () => import("./../../../src/pages/agentless-microsegmentation.jsx" /* webpackChunkName: "component---src-pages-agentless-microsegmentation-jsx" */),
  "component---src-pages-asset-intelligence-jsx": () => import("./../../../src/pages/asset-intelligence.jsx" /* webpackChunkName: "component---src-pages-asset-intelligence-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-business-case-for-microsegmentation-jsx": () => import("./../../../src/pages/business-case-for-microsegmentation.jsx" /* webpackChunkName: "component---src-pages-business-case-for-microsegmentation-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-customers-jsx": () => import("./../../../src/pages/customers.jsx" /* webpackChunkName: "component---src-pages-customers-jsx" */),
  "component---src-pages-discovery-and-visibility-jsx": () => import("./../../../src/pages/discovery-and-visibility.jsx" /* webpackChunkName: "component---src-pages-discovery-and-visibility-jsx" */),
  "component---src-pages-disposable-jump-box-jsx": () => import("./../../../src/pages/disposable-jump-box.jsx" /* webpackChunkName: "component---src-pages-disposable-jump-box-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mapping-to-nist-and-cmcc-jsx": () => import("./../../../src/pages/mapping-to-nist-and-cmcc.jsx" /* webpackChunkName: "component---src-pages-mapping-to-nist-and-cmcc-jsx" */),
  "component---src-pages-microsegmentation-and-endpoint-security-jsx": () => import("./../../../src/pages/microsegmentation-and-endpoint-security.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-and-endpoint-security-jsx" */),
  "component---src-pages-microsegmentation-cyber-insurance-jsx": () => import("./../../../src/pages/microsegmentation-cyber-insurance.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-cyber-insurance-jsx" */),
  "component---src-pages-microsegmentation-fedramp-jsx": () => import("./../../../src/pages/microsegmentation-fedramp.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-fedramp-jsx" */),
  "component---src-pages-microsegmentation-fisma-jsx": () => import("./../../../src/pages/microsegmentation-fisma.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-fisma-jsx" */),
  "component---src-pages-microsegmentation-gdpr-jsx": () => import("./../../../src/pages/microsegmentation-gdpr.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-gdpr-jsx" */),
  "component---src-pages-microsegmentation-hipaa-jsx": () => import("./../../../src/pages/microsegmentation-hipaa.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-hipaa-jsx" */),
  "component---src-pages-microsegmentation-iso-27001-jsx": () => import("./../../../src/pages/microsegmentation-iso-27001.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-iso-27001-jsx" */),
  "component---src-pages-microsegmentation-mitre-jsx": () => import("./../../../src/pages/microsegmentation-mitre.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-mitre-jsx" */),
  "component---src-pages-microsegmentation-nist-csf-jsx": () => import("./../../../src/pages/microsegmentation-nist-csf.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-nist-csf-jsx" */),
  "component---src-pages-microsegmentation-pci-jsx": () => import("./../../../src/pages/microsegmentation-pci.jsx" /* webpackChunkName: "component---src-pages-microsegmentation-pci-jsx" */),
  "component---src-pages-modern-zero-trust-segmentation-jsx": () => import("./../../../src/pages/modern-zero-trust-segmentation.jsx" /* webpackChunkName: "component---src-pages-modern-zero-trust-segmentation-jsx" */),
  "component---src-pages-network-ai-jsx": () => import("./../../../src/pages/network-ai.jsx" /* webpackChunkName: "component---src-pages-network-ai-jsx" */),
  "component---src-pages-network-ai-platform-jsx": () => import("./../../../src/pages/network-ai-platform.jsx" /* webpackChunkName: "component---src-pages-network-ai-platform-jsx" */),
  "component---src-pages-news-room-jsx": () => import("./../../../src/pages/news-room.jsx" /* webpackChunkName: "component---src-pages-news-room-jsx" */),
  "component---src-pages-ransomware-kill-switch-jsx": () => import("./../../../src/pages/ransomware-kill-switch.jsx" /* webpackChunkName: "component---src-pages-ransomware-kill-switch-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-securing-ot-iomt-networks-jsx": () => import("./../../../src/pages/securing-ot-iomt-networks.jsx" /* webpackChunkName: "component---src-pages-securing-ot-iomt-networks-jsx" */),
  "component---src-pages-stopping-ransomware-in-its-tracks-with-innovative-vlan-defense-jsx": () => import("./../../../src/pages/stopping-ransomware-in-its-tracks-with-innovative-vlan-defense.jsx" /* webpackChunkName: "component---src-pages-stopping-ransomware-in-its-tracks-with-innovative-vlan-defense-jsx" */),
  "component---src-pages-unsecured-vp-ns-can-be-a-hot-mess-jsx": () => import("./../../../src/pages/unsecured-VPNs-can-be-a-hot-mess.jsx" /* webpackChunkName: "component---src-pages-unsecured-vp-ns-can-be-a-hot-mess-jsx" */),
  "component---src-pages-zero-trust-architecture-2023-jsx": () => import("./../../../src/pages/zero-trust-architecture-2023.jsx" /* webpackChunkName: "component---src-pages-zero-trust-architecture-2023-jsx" */),
  "component---src-pages-zero-trust-microsegmentation-for-manufacturing-jsx": () => import("./../../../src/pages/zero-trust-microsegmentation-for-manufacturing.jsx" /* webpackChunkName: "component---src-pages-zero-trust-microsegmentation-for-manufacturing-jsx" */),
  "component---src-pages-zero-trust-predictions-2023-jsx": () => import("./../../../src/pages/zero-trust-predictions-2023.jsx" /* webpackChunkName: "component---src-pages-zero-trust-predictions-2023-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-a-zero-trust-way-for-tech-worker-russia-exodus-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/a-zero-trust-way-for-tech-worker-russia-exodus.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-a-zero-trust-way-for-tech-worker-russia-exodus-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-agentless-vs-agent-based-segmentation-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/agentless-vs-agent-based-segmentation.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-agentless-vs-agent-based-segmentation-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-joins-crowdstrike-falcon-zero-trust-assessment-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/airgap-joins-crowdstrike-falcon-zero-trust-assessment.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-joins-crowdstrike-falcon-zero-trust-assessment-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-achieves-soc-2-type-ii-compliance-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/airgap-networks-achieves-soc-2-type-ii-compliance.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-achieves-soc-2-type-ii-compliance-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-announces-13-4-m-series-a-funding-to-deliver-an-industry-first-ransomware-kill-switch™-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/airgap-networks-announces-13-4m-series-a-funding-to-deliver-an-industry-first-ransomware-kill-switch™.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-announces-13-4-m-series-a-funding-to-deliver-an-industry-first-ransomware-kill-switch™-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-raises-13-4-m-in-series-a-funding-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/airgap-networks-raises-13-4m-in-series-a-funding.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-raises-13-4-m-in-series-a-funding-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-ransomware-kill-switch-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/airgap-networks-ransomware-kill-switch.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-networks-ransomware-kill-switch-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-to-demonstrate-agentless-segmentation-for-critical-infrastructure-at-himss-22-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/airgap-to-demonstrate-agentless-segmentation-for-critical-infrastructure-at-himss22.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-airgap-to-demonstrate-agentless-segmentation-for-critical-infrastructure-at-himss-22-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-augmenting-hitrust-csf-compliance-with-airgap-zero-trust-isolation-platform-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/augmenting-hitrust-csf-compliance-with-airgap-zero-trust-isolation-platform.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-augmenting-hitrust-csf-compliance-with-airgap-zero-trust-isolation-platform-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-blackcat-ransomware-as-a-service-and-vmware-esxi-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/blackcat-ransomware-as-a-service-and-vmware-esxi.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-blackcat-ransomware-as-a-service-and-vmware-esxi-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-cybersecurity-ot-defined-explained-and-explored-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/cybersecurity-ot-defined-explained-and-explored.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-cybersecurity-ot-defined-explained-and-explored-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-do-you-do-what-is-right-for-your-business-and-your-future-or-do-you-do-what-the-adversary-wants-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/do-you-do-what-is-right-for-your-business-and-your-future-or-do-you-do-what-the-adversary-wants.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-do-you-do-what-is-right-for-your-business-and-your-future-or-do-you-do-what-the-adversary-wants-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-historical-colonial-pipeline-ransomware-attack-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/historical-colonial-pipeline-ransomware-attack.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-historical-colonial-pipeline-ransomware-attack-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-ransomware-is-forcing-a-rethink-in-enterprise-network-security-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/how-ransomware-is-forcing-a-rethink-in-enterprise-network-security.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-ransomware-is-forcing-a-rethink-in-enterprise-network-security-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-human-operated-ransomware-campaigns-and-ways-to-thwart-it-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/human-operated-ransomware-campaigns-and-ways-to-thwart-it.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-human-operated-ransomware-campaigns-and-ways-to-thwart-it-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-incident-response-and-mitigation-on-vmware-vulnerabilities-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/incident-response-and-mitigation-on-vmware-vulnerabilities.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-incident-response-and-mitigation-on-vmware-vulnerabilities-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-increased-attacks-on-the-healthcare-industry-during-covid-19-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/increased-attacks-on-the-healthcare-industry-during-covid-19.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-increased-attacks-on-the-healthcare-industry-during-covid-19-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-kaseya-ransomware-attack-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/kaseya-ransomware-attack.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-kaseya-ransomware-attack-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-log-4-j-zero-day-vulnerability-what-it-is-why-it-matters-and-how-to-stop-it-with-zero-trust-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/log4j-zero-day-vulnerability-what-it-is-why-it-matters-and-how-to-stop-it-with-zero-trust.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-log-4-j-zero-day-vulnerability-what-it-is-why-it-matters-and-how-to-stop-it-with-zero-trust-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-moving-beyond-mpls-segmentation-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/moving-beyond-mpls-segmentation.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-moving-beyond-mpls-segmentation-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-national-critical-infrastructure-under-attack-clop-ransomware-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/national-critical-infrastructure-under-attack-clop-ransomware.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-national-critical-infrastructure-under-attack-clop-ransomware-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-network-isolation-is-good-business-process-isolation-is-even-better-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/network-isolation-is-good-business-process-isolation-is-even-better.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-network-isolation-is-good-business-process-isolation-is-even-better-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-preventing-ransomware-attacks-against-backup-systems-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/preventing-ransomware-attacks-against-backup-systems.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-preventing-ransomware-attacks-against-backup-systems-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-ransomware-snapshot-in-ot-and-ics-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/ransomware-snapshot-in-ot-and-ics.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-ransomware-snapshot-in-ot-and-ics-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-the-reemergence-of-the-emotet-trojan-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/the-reemergence-of-the-emotet-trojan.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-the-reemergence-of-the-emotet-trojan-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-the-rising-cost-of-malware-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/the-rising-cost-of-malware.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-the-rising-cost-of-malware-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-think-like-a-submariner-to-stop-ransomware-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/think-like-a-submariner-to-stop-ransomware.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-think-like-a-submariner-to-stop-ransomware-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-time-to-move-beyond-vpn-for-zta-access-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/time-to-move-beyond-vpn-for-zta-access.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-time-to-move-beyond-vpn-for-zta-access-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-top-5-ot-risk-trends-facing-the-manufacturing-industry-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/top-5-ot-risk-trends-facing-the-manufacturing-industry.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-top-5-ot-risk-trends-facing-the-manufacturing-industry-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-understanding-mitre-att-ck-ics-and-securing-ics-with-zero-trust-segmentation-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/understanding-mitre-att-ck-ics-and-securing-ics-with-zero-trust-segmentation.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-understanding-mitre-att-ck-ics-and-securing-ics-with-zero-trust-segmentation-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-universal-ztna-for-remote-access-and-campus-locations-anywhere-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/universal-ztna-for-remote-access-and-campus-locations-anywhere.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-universal-ztna-for-remote-access-and-campus-locations-anywhere-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-network-isolation-for-industrial-control-systems-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/zero-trust-network-isolation-for-industrial-control-systems.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-network-isolation-for-industrial-control-systems-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-network-segmentation-for-the-enterprise-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/zero-trust-network-segmentation-for-the-enterprise.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-network-segmentation-for-the-enterprise-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-newsletter-april-2022-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/zero-trust-newsletter-april-2022.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-newsletter-april-2022-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-practical-approaches-for-securing-ot-networks-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/zero-trust-practical-approaches-for-securing-ot-networks.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-practical-approaches-for-securing-ot-networks-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-security-configuration-management-for-operational-technology-and-industrial-controller-system-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/zero-trust-security-configuration-management-for-operational-technology-and-industrial-controller-system.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-zero-trust-security-configuration-management-for-operational-technology-and-industrial-controller-system-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-launches-threatgpt-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-launches-threatgpt/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-launches-threatgpt-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-named-as-the-hot-company-remote-workforce-security-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-named-as-the-hot-company-remote-workforce-security/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-named-as-the-hot-company-remote-workforce-security-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-acquires-netspyglass-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-networks-acquires-netspyglass/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-acquires-netspyglass-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-announces-13-4-m-series-a-funding-to-deliver-an-industry-first-ransomware-kill-switch™-index-1-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-networks-announces-13-4m-series-a-funding-to-deliver-an-industry-first-ransomware-kill-switch™/index-1.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-announces-13-4-m-series-a-funding-to-deliver-an-industry-first-ransomware-kill-switch™-index-1-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-named-winner-of-three-coveted-global-infosec-awards-during-rsa-conference-2023-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-networks-named-winner-of-three-coveted-global-infosec-awards-during-rsa-conference-2023/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-named-winner-of-three-coveted-global-infosec-awards-during-rsa-conference-2023-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-selected-to-join-5-g-open-innovation-lab-development-program-extending-zero-trust-security-to-private-5-g-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-networks-selected-to-join-5g-open-innovation-lab-development-program-extending-zero-trust-security-to-private-5g/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-networks-selected-to-join-5-g-open-innovation-lab-development-program-extending-zero-trust-security-to-private-5-g-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-ransomware-early-detection™-fortifies-the-network-security-perimeter-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/airgap-ransomware-early-detection™-fortifies-the-network-security-perimeter/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-airgap-ransomware-early-detection™-fortifies-the-network-security-perimeter-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-cookie-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/cookie-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-cookie-policy-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-eula-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/eula/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-eula-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-privacy-policy-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-privacy-policy-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-supportterms-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/supportterms/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-supportterms-index-md" */),
  "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-terms-and-conditions-index-md": () => import("./../../../src/templates/text-page.jsx?__contentFilePath=/opt/build/repo/src/content/textPages/terms-and-conditions/index.md" /* webpackChunkName: "component---src-templates-text-page-jsx-content-file-path-src-content-text-pages-terms-and-conditions-index-md" */)
}

